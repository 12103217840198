import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UrlEnum } from '../../enum/url.enum';
import { CampaignDonationTransactionModel } from '../../model/campaign-donation.model';
import { EmergencyDonationModel } from '../../model/emergency-donation.model';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { DonationStatusModel } from '../../model/donation-status.model';

@Injectable({
  providedIn: 'root',
})
export class DonationService {
  public DonationFeeRate: number = 0.014;

  private emergencyDonationApi = `${UrlEnum.SHOHAY_NGO}/emergency-donation`;
  private donationCampaign = `${UrlEnum.SHOHAY_NGO}/ngo-donation/campaign`;
  private donation = `${UrlEnum.SHOHAY_NGO}/ngo-donation/donation`;
  private donationCommentReply = `${UrlEnum.SHOHAY_NGO}/ngo-donation/comment`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public emergencyDonationRequest(
    body: EmergencyDonationModel
  ): Observable<any> {
    return this.utilitiesService.responseHandlerWithAlert(
      this.httpRequestService.post(
        `${this.emergencyDonationApi}/create`,
        body,
        {
          skipAuth: true,
        }
      ),
      true
    );
  }

  public getAllDonation(): Observable<any> {
    return this.httpRequestService
      .get(`${this.emergencyDonationApi}/get-all`, {
        skipAuth: true,
      })
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) =>
          x.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          )
        )
      );
  }

  public getDonationCampaignById(
    ngoId: string,
    projectId: string,
    id: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('ngo_id', ngoId)
      .set('project_id', projectId)
      .set('id', id);

    return this.httpRequestService
      .get(`${this.donationCampaign}/get-by-id`, { params, skipAuth: true })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getDonationListByCampaign(
    ngoId: string,
    projectId: string,
    campaignId: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('ngo_id', ngoId)
      .set('project_id', projectId)
      .set('campaign_id', campaignId);

    return this.httpRequestService
      .get(`${this.donation}/get-by-campaign`, { params, skipAuth: true })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getAllDonationForSuperAdmin(
    page: number,
    size: number
  ): Observable<any> {
    return this.httpRequestService
      .get(`${this.donation}/get-all?page=${page}&size=${size}`)
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public makeCampaignDonation(
    body: CampaignDonationTransactionModel
  ): Observable<any> {
    return this.httpRequestService
      .post(`${this.donation}/create`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getDonationCampaignCommentReply(campaignId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.donationCommentReply}/get-by-campaign/${campaignId}`, {
        skipAuth: true,
      })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }
  public makeReply(replyBody: any): Observable<any> {
    return this.httpRequestService
      .post(
        `${this.donationCommentReply}/create-reply/${replyBody.parent_id}`,
        replyBody
      )
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public updateDonationCampaign(body: any, id: string): Observable<any> {
    return this.httpRequestService
      .patch(`${this.donationCampaign}/update/${id}`, body)
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getDonationByEmail(email: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.donation}/get-by-email?email=${email}`, {
        skipAuth: true,
      })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public updateStatus(id: string, body: DonationStatusModel): Observable<any> {
    return this.httpRequestService
      .patch(`${this.donation}/update-status/${id}`, body)
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }
}
