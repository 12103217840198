<div class="main_div">
  <div *ngIf="showDonationSummary">
    <span style="color: #0054a6">Home > </span
    ><span class="font_14">{{ campaign?.title }}</span>
  </div>

  <div
    class="main_container mt-4 mb-4 d-flex"
    [ngStyle]="{ 'justify-content': showDonationSummary ? 'normal' : 'center' }"
  >
    <div class="left_side">
      <div
        class="img_slider"
        [ngStyle]="{
          'background-image': 'url(' + campaign?.cover_img_vdo?.url + ')'
        }"
      >
        <div class="yellow_title_div" *ngIf="campaign?.is_featured">
          <div class="yellow_title">Featured</div>
        </div>
      </div>
      <div class="organizer_container mt-4">
        <div class="organizer">
          <img
            loading="lazy"
            alt="prochesta"
            src="../../../../../assets/images/icon/prochesta_foundation_icon.svg "
          />
          <span class="organized_text">
            Organized by
            <span style="font-weight: 500">
              {{ campaign?.ngo_name }}
            </span> </span
          ><img
            loading="lazy"
            alt="verified"
            src="../../../../../assets/images/icon/verified_icon_green.svg"
          />
        </div>
        <div class="time">
          <img
            style="margin-right: 8px"
            loading="lazy"
            alt=""
            src="../../../../../assets/images/icon/time-01.svg"
          /><span>{{ campaign?.end_date ? dayLeft : "Ongoing" }}</span>
        </div>
      </div>

      <div style="margin-block: 32px">
        <div class="title">{{ campaign?.title }}</div>
        <div class="subtitle">{{ campaign?.subtitle }}</div>
      </div>
      <div class="our_story">Our Story:</div>
      <div id="campaign_story"></div>

      <!-- Commented as per Mobashwir bhai's direction -->
      <!-- <div class="button_group">
        <button class="white-button-lg w-50">Donate Now</button>
        <button class="white-button-lg w-50">Share</button>
      </div> -->
      
      @if(showFundraisers){
      <hr class="line" />
      <!-- fundraising_team_container -->
      <div class="fundrising_member">
        <div class="fundrising_header">
          <span class="title">Fundraising Members</span>
          <button
            class="yellow-button-lg"
            (click)="joinAsFundraiserComponent.showHideSignUpPopup(true)"
          >
            <img
              class="icon"
              loading="lazy"
              style="margin-right: 8px"
              src="../../../../../assets/images/icon/fundraising_start.svg"
              alt="add"
              title="add"
            />Join As a Fundraiser
          </button>
        </div>

        <!-- Fundraising Teams List -->
        <app-fundraisers-list
          [campaign_id]="campaign_id"
        ></app-fundraisers-list>
      </div>
      }
      <!-- fundraising_team_container end-->

      <hr class="line" />

      <!-- contact_container -->
      <div>
        <span class="contact_person_text">Contact Person</span>
        <div class="founder_child">
          <div class="founder_div">
            <div>
              <img
                src="../../../../../assets/images/mobashwir_bhai.png"
                alt="Profile Picture"
                class="rounded-circle"
              />
            </div>
            <div class="founder_info">
              <div class="contact_details">
                <span class="ceo_name">Mobashwir Hasan</span>
                <span>Organizer</span>
                <span>CEO</span>
                <span>{{ campaign?.ngo_name }}</span>
                <button class="white-button-md">Contact</button>
              </div>
            </div>
          </div>
          <div>
            <img
              src="../../../../../assets/images/icon/next_blue_icon.svg"
              alt="Profile Picture"
              loading="lazy"
            />
          </div>
          <div class="d-flex" style="gap: 16px">
            <div>
              <img
                src="../../../../../assets/images/icon/prochesta_foundation_icon.svg"
                alt="Profile Picture"
                loading="lazy"
                style="height: 90px; width: 90px"
              />
            </div>
            <div>
              <div
                class="d-flex align-items-center"
                style="margin-bottom: 16px"
              >
                <span class="org_title">{{ campaign?.ngo_name }}</span>
                <img
                  src="../../../../../assets/images/icon/verified_icon_green.svg"
                  alt="Profile Picture"
                  loading="lazy"
                  style="width: 24px; height: 24px"
                />
              </div>
              <div class="contact_details">
                <div>NGO</div>
                <div>Bangladesh</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="line" />

      <!-- Announcement -->
      <app-announcement></app-announcement>
      <!-- Announcement end-->

      <!-- Donor-experience -->
      <app-donor-experience
        [showMoreButton]="showMoreDonorExperienceButton"
        [isCommentHasData]="isCommentHasData"
      ></app-donor-experience>
      <!-- Donor-experience end-->
    </div>

    <div *ngIf="showDonationSummary" class="right_side">
      <div class="donation">
        <div class="donation_details d-flex flex-column gap-3">
          <div class="amount d-flex flex-row justify-content-between">
            <span class="font_18 fw-bold">
              ৳{{ campaign?.total_fund_raised | number }}
            </span>
            <span class="font_16 fw-normal"
              >{{ getFundRaisedInPercent }}% raised of ৳{{
                campaign?.fundraising_target | number
              }}
              Goal
            </span>
          </div>

          <div>
            <dx-progress-bar
              class="progress"
              [min]="0"
              [max]="campaign.fundraising_target"
              [value]="campaign.total_fund_raised"
              [showStatus]="false"
            ></dx-progress-bar>

            <span class="font_14"
              >{{ campaign?.total_donors || 0 | number }} Donations</span
            >
          </div>
          <button
            class="blue-button-lg w-100"
            (click)="navigateToCampaignDonation(campaign)"
          >
            <!-- [routerLink]="['/donate-to-campaign']"
            [queryParams]="{
              ngo_id: campaign?.ngo_id,
              project_id: campaign?.project_id,
              id: campaign?.id
            }" -->
            Donate Now
          </button>
          <button class="yellow-button-lg w-100" text="share">Share</button>
          <div class="text-center mt-4 mb-4">
            <img
              loading="lazy"
              src="../../../../../assets/images/icon/trending_up_icon.svg"
              alt="Donor"
              title="donor"
            />
            <span class="font_16 recent_donated_text">{{
              campaign?.total_donors
                ? campaign?.total_donors + " people just donated"
                : "0 donations yet"
            }}</span>
          </div>

          <div
            *ngIf="campaign?.total_donors || campaign?.total_donors > 0"
            class="d-flex flex-column gap-4 mb-4"
          >
            <div class="donor_list">
              <div class="donor_icon_div">
                <img
                  src="../../../../../assets/images/icon/donor_icon_blue.png"
                  alt="Donor"
                  title="donor"
                />
              </div>
              <div class="d-flex gap-2 flex-column">
                <span class="font_18">{{
                  campaign.donation_summary?.recent_donation?.name
                }}</span>
                <span class="font_16">
                  ৳{{
                    campaign.donation_summary?.recent_donation?.amount == (0 | number)
                      ? "Hidden"
                      : (campaign.donation_summary.recent_donation.amount
                        | number)
                  }}

                  <span class="font_14" style="text-decoration: underline"
                    >Recent Donation</span
                  >
                </span>
              </div>
            </div>
            <div class="donor_list">
              <div class="donor_icon_div">
                <img
                  src="../../../../../assets/images/icon/donor_icon_blue.png"
                  alt="Donor"
                  title="donor"
                />
              </div>
              <div class="d-flex gap-2 flex-column">
                <span class="font_18">{{
                  campaign.donation_summary?.top_donation?.name
                }}</span>
                <span class="font_16">
                  ৳{{
                    campaign.donation_summary?.top_donation?.amount == (0 | number)
                      ? "Hidden"
                      : (campaign.donation_summary.top_donation.amount
                        | number)
                  }}
                  <span class="font_14" style="text-decoration: underline"
                    >Top Donation</span
                  >
                </span>
              </div>
            </div>
            <div class="donor_list">
              <div class="donor_icon_div">
                <img
                  src="../../../../../assets/images/icon/donor_icon_blue.png"
                  alt="Donor"
                  title="donor"
                />
              </div>
              <div class="d-flex gap-2 flex-column">
                <span class="font_18">{{
                  campaign.donation_summary?.first_donation?.name
                }}</span>
                <span class="font_16">
                  ৳{{
                    campaign.donation_summary?.first_donation?.amount == (0 | number)
                      ? "Hidden"
                      : (campaign.donation_summary.first_donation.amount
                        | number)
                  }}
                  <span class="font_14" style="text-decoration: underline"
                    >First Donation</span
                  >
                </span>
              </div>
            </div>
          </div>
          <button
            (click)="seeAll()"
            class="white-button-md w-100 mb-2"
            [disabled]="!campaign?.total_donors || campaign?.total_donors === 0"
          >
            See All
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<dx-popup
  [maxWidth]="721"
  [maxHeight]="802"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="donationListPopUp"
  [showTitle]="false"
>
  <div class="donation_list_popup">
    <div class="all_donation_container">
      <div class="cross_button_conatiner">
        <div class="cross_button_div">
          <button class="cross_button" (click)="closePopup()">
            <img
              src="../../../../../assets/images/icon/cross_icon.svg"
              alt="cross"
              loading="lazy"
              title="close"
            />
          </button>
        </div>
        <div class="donation_list_text">
          Donations List ( {{ donationList?.length }})
        </div>
      </div>
      <div class="button_container">
        <button
          (click)="filterDonations('recent')"
          class="most_recent_button"
          style="width: 48%"
        >
          Most Recent
        </button>
        <button
          (click)="filterDonations('top')"
          class="white-button-md"
          style="width: 48%"
        >
          Top Donations
        </button>
      </div>
    </div>
    <dx-scroll-view width="100%" height="70%">
      <div class="all_donation_container">
        <div
          class="donar_container"
          *ngFor="let donor of currentDonationList; let i = index"
        >
          <div class="donor_list">
            <div class="donor_icon_div">
              <img
                src="../../../../../assets/images/icon/donor_icon_blue.png"
                alt="Donor"
                title="donor"
              />
            </div>
            <div class="d-flex gap-2 flex-column">
              <span class="font_18">
                {{ donor.is_hidden_name ? "Anonymous" : donor.name }}
              </span>
              <span class="font_16">
                ৳{{ donor.is_hidden_name_and_amount ? "Hidden" : donor.amount }}
                <span class="hours"
                  >in {{ getDonationTime(donor?.created_at) }}</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
    <div class="donation_button">
      <button
        class="blue-button-lg w-100"
        (click)="navigateToCampaignDonation(campaign)"
      >
        <!-- [routerLink]="['/donate-to-campaign']"
        [queryParams]="{
          ngo_id: campaign?.ngo_id,
          project_id: campaign?.project_id,
          id: campaign?.id
        }" -->
        Donate Now
      </button>
    </div>
  </div>
</dx-popup>

<app-join-as-fundraiser
  [ngo_id]="ngo_id"
  [project_id]="project_id"
  [campaign_id]="campaign_id"
>
</app-join-as-fundraiser>
