import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoCampaignService } from 'src/app/shared/services/ngo-campaign/ngo-campaign.service';
import { RichTextService } from 'src/app/shared/services/rich-text/rich-text.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrl: './announcement.component.scss',
})
export class AnnouncementComponent implements OnInit {
  private campaignId: string;
  public announcement: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly richTextService: RichTextService,
    private readonly ngoCampaignService: NgoCampaignService,
    public readonly localStorageService: LocalStorageService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    // this.route.queryParams.subscribe((params) => {
    //   this.campaignId = params['id'];
    //   if (this.campaignId) {
    //     this.getCampaignAnnouncement();
    //   }
    // });

    this.route.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.campaignId  = decryptedParams.id;
          if (this.campaignId) {
            this.getCampaignAnnouncement();
          }
      } else {
        this.router.navigateByUrl('/dashboard/campaign');
        console.error('Decryption failed');
      }
    });
  }

  private getCampaignAnnouncement(): void {
    this.ngoCampaignService
      .getCampaignAnnouncement(this.campaignId)
      .subscribe((result: any) => {
        if (result?.length > 0) {
          this.announcement = result[0];
          this.announcement.body = this.richTextService.decodeHtml(
            this.announcement.body
          );

          const element = document.getElementById('announcement_body');
          element.innerHTML = this.announcement.body;
        }
      });
  }
}
