import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DevExtremeModule, DxTextBoxModule, DxAutocompleteModule } from 'devextreme-angular';
import { NewsArticleCardsComponent } from '../components/news-article-cards/news-article-cards.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AnnouncementComponent } from '../components/ngo-campaign/announcement/announcement.component';
import { CampaignDetailsComponent } from '../components/ngo-campaign/campaign-details/campaign-details.component';
import { DonorExperienceComponent } from '../components/ngo-campaign/donor-experience/donor-experience.component';
import { FundraisersListComponent } from '../components/ngo-campaign/fundraisers-list/fundraisers-list.component';
import { JoinAsFundraiserComponent } from '../components/ngo-campaign/join-as-fundraiser/join-as-fundraiser.component';
// import { NullWithDefaultPipe } from '../pipe/null-with-default.pipe';

// function HttpLoaderFactory(http: HttpBackend) {
//   return new TranslateHttpLoader(new HttpClient(http));
// }

// const I18N_CONFIG = {
//   // defaultLanguage: 'english',
//   loader: {
//     provide: TranslateLoader,
//     useFactory: HttpLoaderFactory,
//     // deps: [HttpClient],
//     deps: [HttpBackend],
//   },
// };

@NgModule({
  imports: [
    DevExtremeModule,
    DxAutocompleteModule,
    RouterModule,
    CommonModule,
    DxTextBoxModule
    // TranslateModule.forRoot(I18N_CONFIG),
  ],
  declarations: [
    NewsArticleCardsComponent,
    AnnouncementComponent,
    CampaignDetailsComponent,
    DonorExperienceComponent,
    FundraisersListComponent,
    JoinAsFundraiserComponent,
  ],
  exports: [
    NewsArticleCardsComponent,
    AnnouncementComponent,
    CampaignDetailsComponent,
    DonorExperienceComponent,
    FundraisersListComponent,
    JoinAsFundraiserComponent,
  ],
})
export class SharedModule {}
